'use client';

import 'client-only';

import { t } from '@lingui/macro';
import { useApi } from '@prismo-io/core';
import {
  type ApiClient,
  ComingFromType,
  type SWRArgs,
} from '@prismo-io/schemas';
import { useUsermaven } from '@prismo-io/tracking';
import { useSearchParams } from 'next/navigation';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';
import { z } from 'zod';
import { useHubspot } from '../use-hubspot';

export const SignUpWithPasswordSchema = z.object({
  email: z.string().email().trim().min(1, t`L'email est obligatoire.`),
  password: z
    .string()
    .trim()
    .min(4, t`Le mot de passe doit comporter 4 charactères minimum.`),
  firstName: z.string().trim().min(2),
  lastName: z.string().trim().min(2),
  comingFrom: ComingFromType.nullish().optional(),
  community_variation: z.string().nullish().optional(),
  campaign: z.string().trim().nullish().optional(),
});

export type SignUpWithPasswordSchemaT = z.infer<
  typeof SignUpWithPasswordSchema
> & { utm?: any };

const fetcher = async (client: ApiClient, data: SignUpWithPasswordSchemaT) => {
  const {
    email,
    password,
    firstName,
    lastName,
    comingFrom,
    campaign,
    community_variation,
  } = SignUpWithPasswordSchema.parse(data);

  const { error: signUpError } = await client.auth.signUp({
    email,
    password,
    options: {
      data: {
        first_name: firstName,
        last_name: lastName,
        coming_from: comingFrom,
        campaign: campaign,
        community_variation,
        utm: data?.utm ?? {},
      },
    },
  });

  if (signUpError) {
    throw signUpError;
  }

  await client.auth.signInWithPassword({
    email,
    password,
  });

  return true;
};

export function useSignUpWithPassword() {
  const { client } = useApi();

  const usermaven = useUsermaven();

  const hubspot = useHubspot();

  const searchParams = useSearchParams();

  return useSWRMutation(
    'sign-up-with-password',
    async (key, { arg }: SWRArgs<SignUpWithPasswordSchemaT>) => {
      const utm = {} as Record<string, string>;

      for (const key of searchParams.keys()) {
        if (key.startsWith('utm_')) {
          const value = searchParams.get(key);
          if (!!value) {
            utm[key] = value;
          }
        }
      }

      const result = await toast.promise(fetcher(client, { ...arg, utm }), {
        loading: t`Inscription en cours...`,
        success: t`Vous avez bien été authentifié !`,
        error: t`Une erreur est survenue, veuillez vérifier vos données.`,
      });

      if (result) {
        usermaven.track('signed_up', { from: arg.comingFrom || 'APP', ...utm });
      }

      return result;
    },
    {
      onError: () => {
        hubspot.openChat();
      },
    }
  );
}
