'use client';

import 'client-only';

import { useApi } from '@prismo-io/core';
import type { AuthChangeEvent, Session } from '@supabase/supabase-js';
import { useEffect } from 'react';

export function useAuthStateChange(
  callback: (event: AuthChangeEvent, session: Session | null) => void
) {
  const { client } = useApi();

  useEffect(() => {
    const { data: authListener } = client.auth.onAuthStateChange(callback);

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [client, callback]);
}
