'use client';

import 'client-only';

import { useApi } from '@prismo-io/core';
import type { ApiClient, SWRArgs } from '@prismo-io/schemas';
import useSWRMutation from 'swr/mutation';
import { z } from 'zod';

export const ResetPasswordSchema = z.string().trim().min(6);

export type ResetPasswordSchemaT = z.infer<typeof ResetPasswordSchema>;

const fetcher = async (
  client: ApiClient,
  newPassword: ResetPasswordSchemaT
) => {
  const validNewPassword = ResetPasswordSchema.parse(newPassword);

  const { error: resetError } = await client.auth.updateUser({
    password: validNewPassword,
  });

  if (resetError) {
    throw resetError;
  }

  return true;
};

export function useResetPasswordForEmail() {
  const { client } = useApi();

  return useSWRMutation(
    'reset-password',
    (key, { arg }: SWRArgs<{ newPassword: ResetPasswordSchemaT }>) => {
      return fetcher(client, arg.newPassword);
    }
  );
}
