'use client';

import 'client-only';

import { t } from '@lingui/macro';
import { useApi } from '@prismo-io/core';
import type { ApiClient, SWRArgs } from '@prismo-io/schemas';
import { useUsermaven } from '@prismo-io/tracking';
import * as Sentry from '@sentry/nextjs';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';
import { z } from 'zod';

export const SignInWithPasswordSchema = z.object({
  email: z.string().email().trim().min(1, t`L'email est obligatoire.`),
  password: z.string().trim().min(4, t`Le mot de passe est obligatoire.`),
});

export type SignInWithPasswordSchemaT = z.infer<
  typeof SignInWithPasswordSchema
>;

const fetcher = async (client: ApiClient, data: SignInWithPasswordSchemaT) => {
  const { email, password } = SignInWithPasswordSchema.parse(data);

  const { error, data: result } = await client.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    Sentry.setExtra('swr:handler', true);
    throw error;
  }

  if (!result) {
    return false;
  }

  return true;
};

export function useSignInWithPassword() {
  const { client } = useApi();

  const usermaven = useUsermaven();

  return useSWRMutation(
    'sign-in-with-password',
    async (key, { arg }: SWRArgs<SignInWithPasswordSchemaT>) => {
      const result = await toast.promise(fetcher(client, arg), {
        loading: t`Connexion en cours...`,
        success: t`Vous êtes bien authentifié !`,
        error: t`Vos identifiants ne sont pas valides, veuillez les vérifier.`,
      });

      if (result) {
        usermaven.track('sign_in_with_password');
      }

      return result;
    }
  );
}
